define("@woody-lite/core-session/enaxs/stand-in-customer-data", ["exports", "@woody-lite/core-session/resources/client-enax", "@woody-mrs-potato/core-http/services/http"], function (_exports, _clientEnax, _http) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class StandInCustomerDataEnax extends _clientEnax.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "endpoint", '/stand-in/v0/customer-data');
      _defineProperty(this, "cacheStrategy", _http.CACHE_STRATEGIES.NETWORK_ONLY);
      _defineProperty(this, "smc", 'SMGG20240529');
    }
    async done() {
      var _response$data;
      const response = await super.done(...arguments);
      if (response !== null && response !== void 0 && (_response$data = response.data) !== null && _response$data !== void 0 && (_response$data = _response$data.data) !== null && _response$data !== void 0 && _response$data[0]) {
        return {
          fullName: response.data.data[0],
          personType: {
            id: 'NATURAL'
          }
        };
      }
      return {};
    }
  }
  _exports.default = StandInCustomerDataEnax;
});